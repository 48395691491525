

const  Slideshow = [
   
    


        { imageUr: "/ur9lin3.jpg", title: 'JOIN THE NETWORK', text:'Lead the way in innovation and leadership excellence' },
        { imageUr: "/ur9lin5.jpg", title: 'DEDICATION AND CONSISTENCY', text:'In the pursuit of success, dedication is your compass and consistency is your fuel. Keep pushing forward, even when the journey gets tough' },
        { imageUr: "/ur9lin1.JPG", title: 'LEADERSHIP AND LEARNING',text:'Leadership and learning are indispensable to each other'+
        ''
        },
        { imageUr: "/smilewu.jpg", title: 'LIFELONG LEARNERS', text:'Great leaders are lifelong learners, Cultivate a mindset of continuous learning to excel in leadership'}

    
]

 
export default  Slideshow ;